<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <img src="../../public/fkg.png" alt="Logo" />
        <h2 class="brand-text text-primary ml-1 mt-1">STOMATA</h2>
      </b-link>

      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="image" alt="Login V2" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Selamat Datang!
          </b-card-title>
          <b-card-text class="mb-2">
            Masuk dengan menggunakan akun google fkg.unjani.ac.id untuk mengakses website!
          </b-card-text>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="google"
            v-on:click="loginWithGoogle()"
          >
            <feather-icon icon="HomeIcon" class="mr-50" />
            <span class="align-middle">Masuk dengan Google</span>
          </b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Ripple,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      api: "auth/login",
      image: require("@/assets/images/pages/login-v2.svg"),
    };
  },
  mounted() {
    if (localStorage.getItem("accessToken")) {
      window.location = "/home";
    }
  },
  methods: {
    loginWithGoogle() {
      window.open(this.baseUrl + this.api, "_self");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
